import {
  draw,
  generate
} from './src/patterns';

const pattern = {
  draw,
  generate
};

export default pattern;
